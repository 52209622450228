//
// Приемущества компании
// --------------------------------------------------

@import '../../boot.less';

.make-relay(@screen-sm-min, main-advantages, 220px, 6, advantages);

.make-attribute-value(@base, @count, @index, @postfix) when (@postfix = advantages_background_image_url) {
	@url: %("images/s%a.png", @index);
	@attribute-value: url(@url);
}

.make-current-relay(@count, @relay) when (@relay = advantages) {
	.make-attribute-values(background-image, @count, ~'> li', ~'> label', advantages_background_image_url);
	> li > label {
		background-position: top center;
		background-size: auto 80px;
		background-repeat: no-repeat;
		padding-top: 80px;
		> h2 {
			font-size: 18px;
			text-align: center;
			height: 100px;
			background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA0MCAyMCIgcHJlc2VydmVBc3BlY3RSYXRpbz0ieE1pZFlNYXggbWVldCI+PHBhdGggc3Ryb2tlPSIjNWI1YTUwIiBzdHJva2Utd2lkdGg9IjFweCIgZD0iTTEwMDAwIDE5aC0yMDAwMCIvPjwvc3ZnPg==);
			background-size: 100% 20px;
			background-repeat: no-repeat;
			background-position: bottom center;
			&:before {
				content: '';
			}
		}
	}
	> li:hover > label > h2,
	> li > input:checked + label > h2 {
		background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA0MCAyMCIgcHJlc2VydmVBc3BlY3RSYXRpbz0ieE1pZFlNYXggbWVldCI+PHBhdGggc3Ryb2tlPSIjNWI1YTUwIiBzdHJva2Utd2lkdGg9IjFweCIgZD0iTTAgMTlMMjAgMU0yMCAxbDIwIDE4TTAgMTloLTEwMDAwTTQwIDE5aDk5NjAiLz48L3N2Zz4=);
	}
}

#main-advantages {
	padding-top: 18px;
	padding-bottom: 18px;
	margin-top: 18px;
	margin-bottom: 18px;
	> .relay {
		padding: 0;
		list-style: none;
		counter-reset: relay;
		@media (min-width: @screen-sm-min) {
			margin-top: 250px;
		}
		> li {
			> label:hover,
			> label:focus,
			> input:focus {
				text-decoration: underline;
			}
			> label > h2:before {
				counter-increment: relay;
				content: counter(relay) '. ';
				@media (min-width: @screen-sm-min) {
					content: '';
				}
			}
		}
	}
}