// Alerts

.alert-variant(@background; @border; @content-color) {
  background-color: @background;
  border-color: @border;
  color: @content-color;

  hr {
    border-top-color: darken(@border, 5%);
  }
  .alert-link {
    color: darken(@content-color, 10%);
  }
}
