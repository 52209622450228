//
// Логотип шапки
// --------------------------------------------------

@import '../../boot.less';

#header-logo {
	em {
		position: absolute;
		left: 25%;
		top: 60%;
		font-size: 10px;
		@media (min-width: 480px) {
			font-size: 14px;
		}
		@media (min-width: @screen-md-min) {
			font-size: 18px;
		}
	}
}