//
// Форма поиска в подвале сайта
// --------------------------------------------------

@import '../../boot.less';

#footer-search {
	@media (min-width: @screen-sm-min) {
		width: 15em !important;
	}
}

#footer .input-group {
	width: 100%;
}