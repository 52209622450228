//
// Картинка заголовка страницы
// --------------------------------------------------

@import '../../boot.less';

#head-image {
	height: 111px;
	width: 100%;
	position: relative;
	background-position: center center;
	background-size: auto 100%;
	background-repeat: no-repeat;
	top: -45px;
	margin-bottom: -20px;
	z-index: 1;
}