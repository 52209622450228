//
// Форма поиска в главном меню
// --------------------------------------------------

@import '../../boot.less';

.search-form input {
	width: 4em!important;
	@media (min-width: 480px) {
		width: 15em!important;
	}
	@media (min-width: @screen-sm-min) and (max-width: @screen-md-min) {
		width: 8em !important;
	}
}