//
// Элементы меню
// --------------------------------------------------

@import '../../boot.less';

#header-menu {
	position: relative;
	z-index: 2;
	.navbar-form .fa {
		margin-right: 0.5em;
		@media (min-width: @screen-sm) {
			margin-right: 0;
		}
	}
}