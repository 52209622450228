/* cyrillic */
@font-face {
	font-family: 'Cuprum.local';
	font-style: normal;
	font-weight: 400;
	src: local('Cuprum'), local('Cuprum-Regular'), url(fonts/gJovVCIsuVHpE7lUT-5TYfesZW2xOQ-xsNqO47m55DA.woff2) format('woff2');
	unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* latin-ext */
@font-face {
	font-family: 'Cuprum.local';
	font-style: normal;
	font-weight: 400;
	src: local('Cuprum'), local('Cuprum-Regular'), url(fonts/TMj3tAAbnWTxQie9wLnZ2fesZW2xOQ-xsNqO47m55DA.woff2) format('woff2');
	unicode-range: U+0100-024F, U+1E00-1EFF, U+20A0-20AB, U+20AD-20CF, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
	font-family: 'Cuprum.local';
	font-style: normal;
	font-weight: 400;
	src: local('Cuprum'), local('Cuprum-Regular'), url(fonts/rZj40-VH1f1Wt0yg6Fkbcw.woff2) format('woff2');
	unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2212, U+2215, U+E0FF, U+EFFD, U+F000;
}
/* cyrillic */
@font-face {
	font-family: 'Cuprum.local';
	font-style: normal;
	font-weight: 700;
	src: local('Cuprum Bold'), local('Cuprum-Bold'), url(fonts/n3IzcUkIerri6e9sz7DgbhJtnKITppOI_IvcXXDNrsc.woff2) format('woff2');
	unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* latin-ext */
@font-face {
	font-family: 'Cuprum.local';
	font-style: normal;
	font-weight: 700;
	src: local('Cuprum Bold'), local('Cuprum-Bold'), url(fonts/uzbsbn5ow8tu8vOkX1aocxJtnKITppOI_IvcXXDNrsc.woff2) format('woff2');
	unicode-range: U+0100-024F, U+1E00-1EFF, U+20A0-20AB, U+20AD-20CF, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
	font-family: 'Cuprum.local';
	font-style: normal;
	font-weight: 700;
	src: local('Cuprum Bold'), local('Cuprum-Bold'), url(fonts/SfDyn5tWOk7I1we45jpLK1tXRa8TVwTICgirnJhmVJw.woff2) format('woff2');
	unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2212, U+2215, U+E0FF, U+EFFD, U+F000;
}