//
// Кнопки пагинации
// --------------------------------------------------

@import '../../boot.less';

.pagination {
	~ .pagination {
		margin-top: 0;
	}
}