//
// Кнопка прокрутки страницы
// --------------------------------------------------

@import '../../boot.less';

#to-top {
	text-align: center;
	box-sizing: content-box;
	z-index: 5;
	font-size: 40px;
	opacity: 0.25;
	border-radius: 50%;
	padding: 0.2em;
	width: 1em;
	height: 1em;
	line-height: 1em;
	margin-left: -1.5em;
	margin-top: 2em;
	bottom: 0.5em;
	position: fixed;
	transform: translate3d(0,0,0);
	&:hover,
	&:focus {
		opacity: 1;
	}
}
